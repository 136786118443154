<template>
  <div class="md:px-20 padproHide w-full rounded-t-2xl bottomOrder" :class="{ active: boxshow , iosDevice: isWebview && !isLineView  }">
    <div class="button-wrap">
      <button class="text-white bg-main text-sm  bottom-btn" @click="togglebox" v-if="!boxshow">
        訂位選擇
        <img src="@/assets/image/svg_open_sm.svg" alt="">
      </button>
      <button class=" text-main border border-main text-sm mx-auto bottom-btn" @click="togglebox" v-if="boxshow">
        訂位選擇
        <img src="@/assets/image/svg_close_sm.svg" alt="">
      </button>
    </div>

    <div class="block m-auto" v-if="boxshow">
      <div class="flex justify-center md:flex-col text-gray-500">
        <div class="flex flex-col md:flex-row md:justify-center md:items-center ">
          <div class="flex md:block md:w-20 mb-2 md:mb-0">
            <span class="text-xs md:text-base font-semibold mr-1 md:mr-0">成人</span>
            <img class="w-3 md:hidden" src="@/assets/image/svg_adult_gray.svg" alt="adultNumber" />
          </div>
          <div class="flex flex-row border md:h-10 rounded-md border-gray-300  relative shadow w-20 md:w-32">

            <button class="hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-l focus:outline-none cursor-pointer items-center
              " @click.prevent="adultNumber(-1)" :disabled="adultNum === 1">
              <span class="m-auto text-2xl font-extralight">-</span>
            </button>
            <div class="bg-white w-3  md:w-24 text-xs flex  items-center justify-center cursor-default px-3 md:px-6">
              <input type="hidden" class="border-gray-400 focus:outline-none text-center" value="adultNum" />
              <span class="text-sm font-semibold text-black">{{adultNum}}</span>
            </div>
            <button class="hover:bg-gray-200 border-gray-400  h-full w-7 md:w-28 flex rounded-l focus:outline-none cursor-pointer items-center" :class="{isFull :isFull}" @click.prevent="adultNumber(+1)" :disabled="adultNum === store.order_settings.single_time_order_total || (adultNum + childNum)  >= store.order_settings.single_time_order_total">
              <span class="m-auto text-2xl font-extralight">+</span>
            </button>
          </div>

          <span class="font-semibold text-right md:w-12 hidden md:block">位</span>
        </div>

        <div class="flex flex-col md:flex-row md:justify-center md:items-center mx-2 md:mx-0 md:mt-5 ">
          <div class="flex md:block md:w-20 mb-2 md:mb-0">
            <span class="text-xs md:text-base font-semibold mr-1 md:mr-0">小孩</span>
            <img class="w-3 md:hidden" src="@/assets/image/svg_child_gray.svg" alt="adultNumber" />
          </div>
          <div class="flex flex-row border md:h-10 rounded-md border-gray-300  relative shadow  w-20  md:w-32">
            <button class=" hover:bg-gray-200 border-gray-400  h-full w-7 md:w-28 flex rounded-l focus:outline-none cursor-pointer items-center" @click.prevent="childNumber(-1)" :disabled="childNum === 0">
              <span class="m-auto text-2xl font-extralight">-</span>
            </button>
            <div class="bg-white w-3 md:w-24 text-xs flex items-center justify-center cursor-default px-3 md:px-6 ">
              <input type="hidden" class="border-gray-400 focus:outline-none text-center" :value="childNum" />
              <span class="text-sm font-semibold text-black">{{
                childNum
              }}</span>
            </div>
            <button class=" hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-r focus:outline-none cursor-pointer
              " @click.prevent="childNumber(+1)" :class="{isFull :isFull}" :disabled="adultNum === this.store.order_settings.single_time_order_total || (adultNum + childNum) >= this.store.order_settings.single_time_order_total">
              <span class="m-auto text-2xl font-extralight">+</span>
            </button>
          </div>
          <span class="font-semibold md:w-12 text-right hidden md:block">位</span>
        </div>

        <div v-if="childNum >= 1">
          <div class=" flex flex-col md:flex-row md:justify-center md:items-center md:mt-5">
            <div class="flex md:block md:w-20 mb-2 md:mb-0">
              <span class="text-xs md:text-base font-semibold mr-1 md:mr-0">兒童座椅</span>
              <img class="w-3 md:hidden" src="@/assets/image/svg_chair_gray.svg" alt="adultNumber" />
            </div>
            <div class=" flex flex-row border md:h-10 rounded-md border-gray-300 relative shadow w-20 md:w-32">
              <button class=" hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-r focus:outline-none cursor-pointer" @click.prevent="childseatQtyMinus()">
                <span class="m-auto text-2xl font-extralight">-</span>
              </button>
              <div class=" bg-white w-3 md:w-24 text-xs flex items-center justify-center cursor-default px-3 md:px-6">
                <input type="hidden" class="border-gray-400 focus:outline-none text-center" :value="childseat" />
                <span class="text-sm font-semibold text-black">{{childseat}}</span>
              </div>
              <button class=" hover:bg-gray-200 border-gray-400 h-full w-7 md:w-28 flex rounded-r focus:outline-none cursor-pointer" :class="{isFull :isSeatFull}" @click.prevent="childseatQtyPlus()">
                <span class="m-auto text-2xl font-extralight">+</span>
              </button>
            </div>
            <span class="font-semibold md:w-12 text-right hidden md:block">張</span>
          </div>
        </div>
      </div>
      <p class="p-3 md:hidden text-xs text-grayColor-light text-center">
        * 超過
        {{ store.order_settings.single_time_order_total }}
        人或有訂位以外的需求，請使用電話預約！
      </p>
      <Calendar :canOrderTimes="canOrderTimes" :store="store" :openPanel="openPanel" @update="updateDate"></Calendar>

      <div class="wrap">
        <div class="cont">
          <div class="text-center state">
            <button class="w-20 h-9 timeOption rounded-md md:mx-3 mx-1 text-grayColor focus:outline-none text-sm md:text-base" v-for="i in businessHour" :key="i" :class="{
                unavaliableTime: getArrDifference.find((time) => time === i),
                timeoptionActive: orderTime === i,
              }" @click="onClicktime(i)">
              {{ i }}
            </button>
          </div>
        </div>
      </div>

      <p class="md:pb-5 text-grayColor-light text-xs md:text-sm text-center">
        *灰色表已滿，可點選檢視同時段其他可訂位日期
      </p>

      <div class="text-white text-sm md:text-lg flex justify-center border-gray-100 pt-5  mb-5">
        <button class="btn-gray btn mr-3" @click="clearAll"> 清空</button>
        <button class="btn-main btn" :class="{disabledBtn : !isGoNext  || isChangeMonth}" @click="confirmDate">
          選擇
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import Calendar from "./Calendar.vue";
import { cloneDate } from "@/assets/filters/date.js";

export default {
  components: {
    Calendar,
  },
  props: {
    store: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    storeCode: {
      type: String,
    },
    storeID: {
      type: Number,
    },
  },
  data() {
    return {
      openPanel: true,
      adultNum: 2,
      childNum: 0,
      childseat: 0,
      avaliableHour: [],
    };
  },
  mounted() {
    this.adultNum = this.$store.state.searchData.total_people;
  },
  methods: {
    getBusinessTime() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-times/${this.apiDate}/${this.count}`;
      const vm = this;
      vm.isloading = true;
      vm.$http
        .get(api)
        .then((response) => {
          this.$store.commit(
            "BUSINESSHOUR",
            response.data.return.business_times
          );
          vm.avaliableHour = response.data.return.can_order_times;
          vm.isloading = false;
          this.defaultTime();
        })
        .catch((error) => {
          vm.isloading = false;
        });
    },
    togglebox() {
      this.$store.dispatch("togglebox");
    },
    adultNumber(num) {
      this.adultNum = this.adultNum + num;
      this.changeNumber();
    },
    childNumber(num) {
      this.childNum = this.childNum + num;
      this.changeNumber();
    },
    childseatQtyPlus() {
      if (this.childseat < this.childNum) {
        this.childseat++;
      } else {
        this.childseat = this.childNum;
      }
    },
    childseatQtyMinus() {
      if (this.childseat === 0) {
        alert("不能再少了");
      } else {
        this.childseat -= 1;
      }
    },
    changeNumber() {
      if (
        this.adultNum + this.childNum >
        this.store.order_settings.single_time_order_total
      ) {
        alert("超過人數限制");
      } else {
        this.adultNum = this.adultNum;
        this.childNum = this.childNum;
      }
      if (this.childNum < this.childseat) {
        this.childseat = this.childNum;
      }
    },
    clickDategetBusinessTime() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-times/${this.apiDate}/${this.count}`;
      const vm = this;
      vm.isloading = true;

      if (this.apiDate !== "NaN-NaN-NaN") {
        vm.$http
          .get(api)
          .then((response) => {
            this.$store.commit(
              "BUSINESSHOUR",
              response.data.return.business_times
            );
            vm.avaliableHour = response.data.return.can_order_times;
            vm.isloading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              vm.isloading = false;
            }
          });
      }
      this.$store.commit("CLICKYEAR", this.tempValue.getFullYear());
      this.$store.commit("CLICKMONTH", this.tempValue.getMonth() + 1);
      this.$store.commit("CLICKDAY", this.tempValue.getDate());
      this.$store.commit("CLICKWEEK", this.tempValue.getDay());
    },
    updateDate() {
      this.clickDategetBusinessTime();
    },
    anotherDate(data) {
      let d = new Date(data),
        m1 = d.getMoth(data) + 1,
        d1 = d.getDate(data),
        y1 = d.getFullYear(data);
      if (m1 < 10) {
        m1 = "0" + m1;
      }
      if (d1 < 10) {
        d1 = "0" + d1;
      }
      const OptionDate = y1 + "-" + m1 + "-" + d1;
      const result = this.canOrderTimes.find((word) => word === OptionDate);
      return result;
    },
    getDay(cell) {
      return cell.date.getDate();
    },
    onClicktime(i) {
      this.$store.commit("CLICKORDERTIME", i);
      localStorage.setItem("orderTime", JSON.stringify(this.orderTime));
      this.getcanOrderDate();

      if (this.avaliableHour.includes(this.orderTime)) {
        this.$store.commit("ISGONEXT", true);
      } else {
        this.$store.commit("ISGONEXT", false);
      }
    },
    getcanOrderDate() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-date/${this.orderTime}/${this.count}`;
      const vm = this;
      vm.$http.get(api).then((response) => {
        this.$store.commit("CANORDERTIMES", response.data.return.dates);
      });
    },
    confirmDate() {
      const vm = this;
      localStorage.setItem("adultNum", vm.adultNum); //存入資料(key,value)
      localStorage.setItem("childNum", vm.childNum);
      localStorage.setItem("childseat", vm.childseat);
      localStorage.setItem("tempValue", vm.tempValue);
      localStorage.setItem("orderTime", vm.orderTime);

      if (this.orderTime == null || undefined || "") {
        alert("請選擇用餐時間");
      } else {
        if (!this.isGoNext) {
          alert("請選擇可用餐時段");
        } else {
          this.$router.push(`/store/${this.storeCode}/info`);
        }
      }
    },
    defaultTime() {
      const defaultArray = [];
      const today = new Date().toLocaleDateString();
      const found = this.avaliableHour.find((element) => {
        const ele = new Date(today + " " + element);
        const now = new Date();
        return ele > now;
      });
      defaultArray.push(found);
      this.$store.commit("CLICKORDERTIME", defaultArray[0]);

      if (this.orderTime.length === 0) {
        this.$store.commit("ISGONEXT", false);
      } else {
        this.$store.commit("ISGONEXT", true);
      }
      // return defaultArray;
    },
    clearAll() {
      this.adultNum = 1;
      this.childNum = 0;
      this.childseat = 0;
      // this.orderTime = "";
      this.$store.commit("ORDERTIME", "");
      this.$store.commit("TEMPVALUE", cloneDate(this.value));
      // this.tempValue = cloneDate(this.value);
    },
  },
  computed: {
    businessHour() {
      return this.$store.state.businessHour;
    },
    canOrderTimes() {
      return this.$store.state.canOrderTimes;
    },
    isChangeMonth() {
      return this.$store.state.isChangeMonth;
    },
    checkIsAppleDevice() {
      if (/(iPhone)/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
    isWebview() {
      if (this.checkIsAppleDevice) {
        let useragent = navigator.userAgent;
        let rules = ["WebView", "(iPhone|iPod|iPad)(?!.*Safari/)"];
        let regex = new RegExp(`(${rules.join("|")})`, "ig");
        return Boolean(useragent.match(regex));
      } else {
        return false;
      }
    },
    isLineView() {
      let u = navigator.userAgent;
      let isLineApp = u.indexOf("Line") > -1; // Line 內建瀏覽器
      if (isLineApp) {
        return true;
      } else {
        return false;
      }
    },
    isFull() {
      if (this.count === this.store.order_settings.single_time_order_total) {
        return true;
      } else {
        return false;
      }
    },
    isSeatFull() {
      const maxSeat = this.childNum;
      if (this.childseat === maxSeat) {
        return true;
      } else {
        return false;
      }
    },
    boxshow() {
      return this.$store.state.boxshow;
    },
    orderTime() {
      return this.$store.state.orderTime;
    },
    isGoNext() {
      return this.$store.state.isGoNext;
    },
    value() {
      return this.$store.state.value;
    },
    tempValue() {
      return this.$store.state.tempValue;
    },
    getArrDifference() {
      return this.businessHour
        .concat(this.avaliableHour)
        .filter(function (v, i, arr) {
          const unavaliable = arr.indexOf(v) === arr.lastIndexOf(v);
          return unavaliable;
        });
    },
    getday() {
      let today = new Date();
      return today.getDay();
    },
    apiDate() {
      let d = new Date(this.tempValue),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    },
    count() {
      return this.adultNum + this.childNum;
    },
  },
  created() {
    this.getBusinessTime();

    if (this.orderTime.length === 0) {
      this.$store.commit("ISGONEXT", false);
    } else {
      this.$store.commit("ISGONEXT", true);
    }
  },
};
</script>

<style lang="scss">
.button-wrap {
  padding: 20px 0 20px 0;
}
.bottom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 2.25rem;
  margin: 1rem auto;
  border-radius: 3px;
}
.isFull {
  cursor: default;
  opacity: 0.7;
  background: rgba(236, 236, 236, 0.582);
  pointer-events: none;
}

.bottomOrder {
  background-color: white;
  overflow-y: auto;
  min-height: 73vh;
  max-height: 95vh;
  position: fixed;
  bottom: 0px;
  right: 0;
  left: 0;
  transform: translateY(79%);
  transition: all 0.5s;
  z-index: 999;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 2.8px rgba(0, 0, 0, 0.008),
    0px 0px 9.4px rgba(0, 0, 0, 0.012), 0px 0px 42px rgba(167, 162, 162, 0.02);

  &.active {
    transform: translateY(0px);
  }
}

@media (min-width: 768px) {
  .bottomOrder {
    min-height: 780px;
    max-height: 900px;
    transform: translateY(680px);
  }
}

// @media only screen and (device-height: 667px) {
//   .iosDevice {
//     transform: translateY(75%);
//     min-height: 692px;
//     overflow-y: scroll;
//   }
// }

// @media only screen and (device-height: 736px) {
//   .iosDevice {
//     transform: translateY(77%);
//     min-height: 700px;
//     // max-height: 100vh;
//   }
// }

// @media only screen and (device-height: 812px) {
//   .iosDevice {
//     transform: translateY(75%);
//     min-height: 752px;
//     max-height: 90vh;
//   }
// }

// @media only screen and (device-height: 844px) {
//   .iosDevice {
//     transform: translateY(75%);
//     min-height: 774px;
//     max-height: 90vh;
//   }
// }

// @media only screen and (device-height: 896px) {
//   .iosDevice {
//     transform: translateY(75%);
//     min-height: 836px;
//     max-height: 90vh;
//   }
// }

// @media only screen and (device-height: 926px) {
//   .iosDevice {
//     transform: translateY(88%);
//     // min-height: 800px;
//     // max-height: 80vh;
//   }
// }

.disabledBtn {
  pointer-events: none;
  opacity: 0.5;
}
</style>