<template>
  <div class="padproHide py-5 px-8 md:px-10 tabBg">
    <div id="tabs" class="container">
      <div class="tabs text-sm md:text-lg flex justify-between md:justify-around text-grayColor-darkest py-5">
        <a class=" cursor-pointer" @click="activetab='introduction'" :class="[ activetab === 'introduction' ? 'activeTab' : '' ]">店家介紹</a>
        <a class=" cursor-pointer" @click="activetab='menus'" :class="[ activetab === 'menus' ? 'activeTab' : '' ]">酒單菜單</a>
        <a class=" cursor-pointer" @click="activetab='map'" :class="[ activetab === 'map' ? 'activeTab' : '' ]">地圖</a>
        <a class=" cursor-pointer" @click="activetab='comments'" :class="[ activetab === 'comments' ? 'activeTab' : '' ]">用餐評論</a>
      </div>

      <div class="content">
        <div v-if="activetab === 'introduction'" class="tabcontent">
          <div class="bg-fill rounded-lg  p-5 text-grayColor-darkest">
            <!-- <p class="block storeSummary " v-html="store.store.summary" :class="{'ellipsis' :store.store.summary.length > 210 && hideContent === false}">
            </p> -->
             <p class="block storeSummary " v-html="store.store.summary">
            </p>
            <!-- <a href="#" class="block mt-5 underline" @click.prevent="hideContent = true" v-if="store.store.summary.length > 210 && hideContent ===false">閱讀全部</a> -->
          </div>
        </div>
        <div v-if="activetab === 'menus'" class="tabcontent">
          <div class="pb-5 flex">
            <button class="menuBtn md:px-5 px-2 mr-1 md:mr-5 hover:bg-gray-200 focus:outline-none flex justify-center items-center" @click="activeMenuTab = 'food'" :class="{ 'activeMenu': this.activeMenuTab === 'food'}" v-if="
                  store.store.images.menus.length > 0">
              <span class="flex items-center pr-2 text-xs md:text-base">看菜單</span>
              <img class="md:w-6" src="@/assets/image/svg_menu.svg" alt="menu" />
            </button>
            <button class="menuBtn md:px-5 px-2 mr-1 md:mr-5 hover:bg-gray-200 focus:outline-none flex justify-center items-center" :class="{ 'activeMenu': this.activeMenuTab === 'wine'}" v-if="store.store.images.wines.length > 0 " @click="activeMenuTab = 'wine'">
              <span class="flex items-center pr-2 text-xs md:text-base">看酒單</span>
              <img class="md:w-6" src="@/assets/image/svg_wine.svg" alt="wine-menu" />
            </button>
          </div>
          <div class="all-menu md:hidden" v-if="store.store.images.menus.length > 0 || store.store.images.wines.length > 0">
            <div class="food-menu" v-if="activeMenuTab === 'food'">
              <flickity class="flickity " ref="flickity" :class="{hidebtnSm : store.store.images.menus.length < 3 }" :options="flickityOptions" v-viewer>
                <img v-for="src in store.store.images.menus" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
              </flickity>
            </div>

            <div class="wine-menu" v-if="activeMenuTab === 'wine'">
              <flickity class="flickity " ref="flickity"  :class="{hidebtnSm : store.store.images.wines.length < 3 }" :options="flickityOptions" v-viewer>
                <img v-for="src in store.store.images.wines" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
              </flickity>

            </div>
          </div>
          <div class="all-menu hidden md:block" v-if="store.store.images.menus.length > 0 || store.store.images.wines.length > 0">
            <div class="food-menu" v-if="activeMenuTab === 'food'">
              <flickity class="flickity " :class="{hidebtn : store.store.images.menus.length < 4}" ref="flickity" :options="flickityOptions" v-viewer>
                <img v-for="src in store.store.images.menus" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
              </flickity>

            </div>

            <div class="wine-menu" v-if="activeMenuTab === 'wine'">
              <flickity class="flickity" :class="{hidebtn : store.store.images.menus.wines < 4}" ref="flickity" :options="flickityOptions" v-viewer>
                <img v-for="src in store.store.images.wines" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
              </flickity>

            </div>
          </div>
          <div class="noMenu-image rounded-lg h-52 md:h-96 flex justify-center items-center" v-if="
              store.store.images.menus.length === 0 &&
              store.store.images.wines.length === 0">
            <div class="text-center bg-blue-200 md:p-10 p-5 text-white md:w-1/2 md:h-1/2 bg-opacity-25 rounded-lg">
              <p class="font-bold text-sm md:text-2xl pb-6 z-10">
                店家尚未提供菜單
              </p>
              <span class="text-xs md:text-sm">歡迎聯絡店家詢問更多資訊</span>
            </div>
          </div>

        </div>
        <div v-if="activetab === 'map'" class="tabcontent">
          <Map :location="store.store.lat_lng"></Map>
        </div>
        <div v-if="activetab === 'comments'" class="tabcontent">
          <div class="text-xs md:text-lg" id="review" v-if="!loading">
            <div class="flex items-center">
              <div class="w-1/4">
                <p class="font-bold text-main">
                  {{ store.comments.avg_score }} /
                  {{ store.comments.count.total }}則
                </p>
              </div>
              <div class="flex star mr-4">
                <span v-for="(item, index) in average_star" class="star-item mr-2" :key="index" :class="item"></span>
              </div>
            </div>
            <div class="flex mt-5 bg-gray-50 text-grayColor-darkest mb-1">
              <span class="w-1/3 ">非常棒 / {{ store.comments.count[5] }}則</span>
              <div class="bg-rating-darkest h-5 md:h-8" :style="{ width: levelCount[4]}"></div>
            </div>
            <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
              <span class="w-1/3  ">蠻好 / {{ store.comments.count[4] }}則</span>
              <div class="bg-rating-dark h-5  md:h-8" :style="{ width: levelCount[3] }"></div>
            </div>
            <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
              <span class="w-1/3  ">一般 / {{ store.comments.count[3] }}則</span>
              <div class="bg-rating h-5  md:h-8" :style="{ width: levelCount[2] }"></div>
            </div>
            <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
              <span class="w-1/3  ">微差 / {{ store.comments.count[2] }}則</span>
              <div class="bg-rating-light h-5  md:h-8" :style="{ width: levelCount[1] }"></div>
            </div>
            <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
              <span class="w-1/3 ">糟透了 / {{ store.comments.count[1] }}則</span>
              <div class="bg-rating-lightest h-5 md:h-8" :style="{ width: levelCount[0] }"></div>
            </div>
          </div>

          <div class="pt-5 md:pt-10" v-if="!loading">
            <div class="py-5 border-t border-b border-gray-200" v-for="(comment, index) in store.comments.datas" :key="index">
              <div class="flex items-center">
                <h5 class="pr-3 text-xs font-bold md:text-2xl text-grayColor-darkest">
                  {{ comment.score }}
                </h5>
                <span v-for="(item, index) in single_star(comment.score)" class="star-item mr-2" :key="index" :class="item"></span>
                <!-- <span class="text-xs text-gray-400">{{ comment.created_at }}</span> -->
              </div>
              <h5 class="py-4 text-xs md:text-2xl font-semibold text-grayColor-darkest">
                {{ comment.title }}
              </h5>

              <div class="flex items-center">
                <span class="flex items-center text-grayColor text-xs font-semibold mr-2">
                  <img class="w-4 mr-2 " src="@/assets/image/svg_user.svg" alt="commentBy" />
                  {{ comment.name }}</span>
                <span class="text-xs text-gray-400">{{ displayCommentDate(comment.created_at) }}</span>
              </div>

              <p class="text-xs md:text-lg font-light tracking-wider pt-4 pb-6 md:pb-10 text-grayColor-darkest word-break">
                {{ comment.body }}
              </p>
              <div>
                <div class="comment-image-wrap md:hidden">
                  <div class="commentImages" v-viewer>
                    <img v-for="src in comment.images" :key="src" :src="src" class="rounded cursor-pointer commentImage" >
                    <!-- <span class="moreImages" v-if=" index !==i && comment.images.length > 4" @click="clickShowMore(index)">+{{comment.images.length -4 }}</span> -->
                  </div>
                </div>

                <div class="comment-image-wrap hidden md:block">
                  <div class="commentImages" v-viewer>
                    <img v-for="src in comment.images" :key="src" :src="src" class="rounded cursor-pointer commentImage" >
                    <!-- <span class="moreImages" v-if=" index !==i && comment.images.length > 4" @click="clickShowMore(index)">+{{comment.images.length -4 }}</span> -->
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Map from "@/components/Map.vue";
import Footer from "@/components/Footer.vue";
import moment from "moment";
import Flickity from "vue-flickity";
const lengths = 5;
const starOn = "on";
const starHalf = "half";
const starOff = "off";

export default {
  name: "Tabs",
  components: {
    Carousel,
    Slide,
    Navigation,
    Map,
    Footer,
    Flickity,
  },
  props: {
    store: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    average_star: {
      type: Array,
    },

    levelCount: {
      type: Array,
    },
    filterData: {
      type: Array,
    },
  },
  data() {
    return {
      activetab: "introduction",
      showmore: true,
      activeMenuTab: "food",
      current: 0,
      hideContent: false,
      isShowmore: [],
      i: -1,
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
        freeScroll: false,
        contain: true,
      },
    };
  },
  methods: {
    jumpToPage(page) {
      const vm = this;
      vm.current = page - 1;
    },
    displayCommentDate(date) {
      const value = moment(date).format("YYYY/MM/DD");
      return value;
    },
    clickShowMore(index) {
      this.i = index;
      // let array = [];
      // let i = array.indexOf(item);
      // if (i < 0) {
      //   array.push(item);
      // } else {
      //   array.splice(i, 1);
      // }
      // this.isShowmore = array;
      // this.showmore = !this.showmore;
    },
    single_star(score) {
      const vm = this;
      let single_star = [];
      // for (let i = 0; i < score; i++) {
      // const item = this.store.return.comments.datas[i];
      let fullstar = Math.floor(score);

      for (let i = 0; i < fullstar; i++) {
        single_star.push(starOn);
      }
      if (single_star.length < lengths) {
        let offstar = lengths - single_star.length;
        for (let i = 0; i < offstar; i++) {
          single_star.push(starOff);
        }
      }
      // }
      return single_star;
    },
  },

  created() {},
};
</script>

<style lang="scss">
.carousel__next {
  position: absolute;
  right: 20px;
  font-size: 1rem;
  color: white;
  outline: none;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}

.carousel__next :focus {
  outline: none;
}

.carousel__prev {
  position: absolute;
  left: 20px;
  font-size: 1rem;
  color: white;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}

.carousel__viewport,
.carousel__track,
.carousel__slide,
.carousel__slide img {
  height: 100%;
  object-fit: cover;
}
.menuSwiperSm {
  height: 150px;
}

@media (min-width: 768px) {
  .menuSwiperSm {
    height: 200px;
  }
}

.menuBtn {
  border-radius: 3px;
  width: 90px;
  height: 25px;
  color: #737992;
  border: 1px solid #737992;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .menuBtn {
    border-radius: 4px;
    width: 140px;
    height: 40px;
  }
}

.tabBg {
  background: #f9f9f9;
}

.commentForth :nth-child(4) {
  opacity: 0.4;
}
.word-break {
  word-break: break-word;
}

.carousel__prev.smHidden,
.carousel__next.smHidden {
  display: block;
}
@media (min-width: 768px) {
  .carousel__prev.smHidden,
  .carousel__next.smHidden {
    display: none;
  }
}
.carousel__prev.padHidden,
.carousel__next.padHidden {
  display: none;
}
@media (min-width: 768px) {
  .carousel__prev.padHidden,
  .carousel__next.padHidden {
    display: block;
  }
}

.comment-image-wrap {
  width: 100%;
  margin: 0 auto;
}

.commentImages {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.moreImages {
  height: 58px;
  width: 58px;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 200;
  line-height: 58px;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

@media (min-width: 375px) {
  .moreImages {
    height: 77px;
    width: 77px;
    line-height: 77px;
  }
}
@media (min-width: 414px) {
  .moreImages {
    height: 87px;
    width: 87px;
    line-height: 87px;
  }
}

@media (min-width: 484px) {
  .moreImages {
    height: 114px;
    width: 114px;
    line-height: 114px;
  }
}

@media (min-width: 768px) {
  .moreImages {
    height: 150px;
    width: 150px;
    line-height: 150px;
    right: 12px;
    text-align: center;
    font-size: 3rem;
  }
}

.commentImage {
  aspect-ratio: 1;
  width: 58px;
  min-height: 58px;
  object-fit: cover;
}
@media (min-width: 375px) {
  .commentImage {
    width: 77px;
    min-height: 77px;
  }
}
@media (min-width: 414px) {
  .commentImage {
    width: 87px;
    min-height: 87px;
  }
}

@media (min-width: 484px) {
  .commentImage {
    width: 114px;
    min-height: 114px;
  }
}

@media (min-width: 768px) {
  .commentImage {
    width: 150px;
    min-height: 150px;
  }
}



.storeSummary {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.17rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.83rem;
  }
  h6 {
    font-size: 0.67rem;
  }
}
.storeSummary {
  .ql-indent-1 {
    padding-left: 3em;
  }
  .ql-indent-2 {
    padding-left: 6em;
  }
  .ql-indent-3 {
    padding-left: 9em;
  }
  .ql-indent-4 {
    padding-left: 12em;
  }
  .ql-indent-5 {
    padding-left: 15em;
  }
  .ql-indent-6 {
    padding-left: 18em;
  }
  .ql-indent-7 {
    padding-left: 21em;
  }
  .ql-indent-8 {
    padding-left: 24em;
  }
}

.flickity.hidebtnSm button {
  display: none;
}

.flickity.hidebtn button {
  display: none;
}
</style>