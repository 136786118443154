
<template >
  <div v-if="!loading" :class="{mobildFixed : cardboxshow}">

    <div class="app-back-icon" @click="backToApp">
      <img src="@/assets/image/app-back.png" alt="back icon" />
    </div>

    <CarouselBanner :storeInfo="store" :loading="loading" />
    <div class="flex justify-center bg-white lg:bg-fill-light">
      <main class="container container-px">
        <div class="lg:flex lg:flex-row gap-10  justify-center">
          <div class="left" v-if="!loading">
            <h1 class="h1Font h1pd font-bold">
              {{ store.store.name }}
            </h1>
            <div class="flex gap-4">
              <div v-for="(item, i) in store.store.tags" :key="i" class="event-tag">
                <img src="@/assets/image/tag-03.svg" alt="tag icon" />
                {{item}}
              </div>
            </div>
            
            <!-- <div class="padproHide mt-2 md:mt-5 px-8 md:px-10" v-if="!loading"> -->
            <div class="padproHide mt-2 px-8 md:px-10" v-if="!loading">
              <div class="flex items-center pb-5 tracking-wider">
                <h5 class="pr-3 font-bold text-xs md:text-2xl">
                  {{ store.comments.avg_score }}
                </h5>
                <span v-for="(item, index) in average_star" class="star-item mr-2" :key="index" :class="item"></span>
                <span class="text-grayColor text-xs md:text-2xl">(</span>
                <span class="text-grayColor font-bold text-xs md:text-2xl">
                  {{ store.comments.count.total }}</span>
                <span class="text-grayColor text-xs md:text-2xl">)</span>
              </div>
              <CardStoreInfo></CardStoreInfo>
            </div>
            <div class="pt-6 text-gray-600  padproHiddenFlex">
              <div class="flex justify-center items-center py-2 pr-7">
                <img class="pr-2" src="@/assets/image/svg_comment.svg" alt="comment" />
                <a class="flex items-center cursor-pointer border-b border-gray-400 " @click="toComment">
                  看評論
                </a>
              </div>
              <div class="flex justify-center items-center py-2 pr-7">
                <img class="pr-2" src="@/assets/image/svg_map.svg" alt="map" />
                <a class="flex items-center cursor-pointer border-b border-gray-400 " @click="toMap">
                  看地圖
                </a>
              </div>
              <div class="relative shareBoxWrap">
                <div class="flex justify-center items-center py-2 relative cursor-pointer" @click="toggleShareLink = !toggleShareLink">
                  <img class="pr-2" src="@/assets/image/svg_share.svg" alt="share-link" />
                  <p>分享連結</p>
                </div>
                <transition enter-active-class="transition ease-out duration-300 transform" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform" leave-from-class="opacity-100" leave-to-class="opacity-0" v-show="toggleShareLink" class="shareBox origin-top-right rounded-lg shadow-lg z-50 ">
                  <div class="rounded-md bg-gray-lightest shadow-xs text-gray-second ">
                    <div class="flex flex-col bg-white w-full rounded-lg p-2">
                      <span>
                        <i class="fab fa-facebook-square text-lg mr-1"></i>
                        <a class="text-sm pl-2" :href="share_fb" target="_blank">分享</a>
                      </span>
                      <div class="relative">
                        <input disabled class="inputStyle w-full" type="text" :placeholder="website" />
                        <button class="copyIcon" v-clipboard:copy="website" @click.stop="handleSuccess" title="Copy Link" name="copy">
                          <i class="far fa-clone"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>

            </div>
            <Tabs :store="store" :loading="loading" :average_star="average_star" :single_star="single_star" :levelCount="levelCount" :filterData="filterData"></Tabs>
            <div class="padproHide mbgap" >
              <Footer />
            </div>
            <!-- <div class="hidden lg:block padproHidden"> -->
            <div class="padproHidden">
              <div class="bg-fill rounded-lg mt-8 p-5 storeSummary" v-html="store.store.summary"></div>
              <div>
                <div class="mt-10" v-if="!loading">
                  <div class="pb-5 flex">
                    <button class="btn-menu flex items-center justify-center" @click="clickMenu('food')" :class="{ 'cursor-not-allowed': store.store.images.menus.length < 1 ,'activeMenu': this.activeMenuTab === 'food'}" v-if="store.store.images.menus.length > 0 ">
                      <span class="flex items-center pr-3 ">看菜單</span>
                      <img class="pr-2 w-" src="@/assets/image/svg_menu15.svg" alt="menu" />
                    </button>

                    <button class="btn-menu flex items-center justify-center" :class="{ 'cursor-not-allowed': store.store.images.wines.length < 1 , 'activeMenu': this.activeMenuTab === 'wine' }" v-if="store.store.images.wines.length > 0 " @click="clickMenu('wine')">
                      <span class="flex items-center pr-3">看酒單</span>
                      <img class="pr-2" src="@/assets/image/svg_wine_15.svg" alt="wine-menu" />
                    </button>
                  </div>

                  <div class="all-menu" v-if="store.store.images.menus.length > 0 || store.store.images.wines.length > 0">
                    <div class="food-menu" v-if="activeMenuTab === 'food'">
                      <flickity class="flickity " :class="{hidebtn : store.store.images.menus.length < 4}" ref="flickity" :options="flickityOptions" v-viewer>
                        <img v-for="src in store.store.images.menus" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
                      </flickity>
                      <!-- <Carousel :items-to-show="3" :transition="1000" v-if="!loading" class="menuSwiper" :wrap-around="false">
                        <Slide v-for="src in store.store.images.menus" :key="src">
                          <img :src="src" alt="" class="h-full w-full cursor-pointer pr-3" v-viewer />
                        </Slide>
                        <template #addons>
                          <Navigation class="focus:outline-none" v-if="store.store.images.menus.length > 3" />
                        </template>
                      </Carousel> -->
                    </div>

                    <div class="wine-menu" v-if="activeMenuTab === 'wine'">
                      <flickity class="flickity " :class="{hidebtn : store.store.images.wines.length < 4}" ref="flickity" :options="flickityOptions" v-viewer>
                        <img v-for="src in store.store.images.wines" :key="src" :src="src" alt="" class=" cursor-pointer carousel-cell" />
                      </flickity>
                      <!-- <Carousel :items-to-show="3" :transition="1000" v-if="!loading" class="menuSwiper" :wrap-around="false">
                        <Slide v-for="src in store.store.images.wines" :key="src">
                          <img :src="src" alt="" class="h-full w-full cursor-pointer pr-3" v-viewer />
                        </Slide>
                        <template #addons>
                          <Navigation class="focus:outline-none " v-if="store.store.images.wines.length > 3" />
                        </template>
                      </Carousel> -->
                    </div>

                    <div class="flex items-center py-5" v-if="store.store.images.menus.length > 0 || store.store.images.wines.length > 0">
                      <img class="pr-2" src="@/assets/image/svg_magnifier.svg" alt="wine-menu" />
                      <p class="text-gray-500 cursor-pointer">點擊圖片可放大</p>
                    </div>
                  </div>

                  <div class="noMenu-image rounded-lg h-96 flex justify-center items-center" v-if="store.store.images.menus.length === 0 && store.store.images.wines.length === 0">
                    <div class="text-center bg-blue-200 p-10 text-white w-1/2 h-1/2 bg-opacity-25 rounded-lg ">
                      <p class="font-bold text-2xl pb-6 z-10">店家尚未提供菜單</p>
                      <span class="text-sm">歡迎聯絡店家詢問更多資訊</span>
                    </div>
                  </div>
                </div>
              </div>

              <Map :location="store.store.lat_lng"></Map>

              <div class="bg-white rounded-md mt-10 px-5">
                <div class="pt-5" id="review" v-if="!loading">
                  <p class="text-2xl text-main pb-5">用餐評論</p>
                  <div class="flex items-center">
                    <div class="w-32">
                      <p class="text-2xl font-bold text-main">
                        {{ store.comments.avg_score }} /
                        {{ store.comments.count.total }}則
                      </p>
                    </div>
                    <div class="flex star mr-4">
                      <span v-for="(item, index) in average_star" class="star-item mr-2" :key="index" :class="item"></span>
                    </div>
                  </div>
                  <div class="flex mt-5 bg-gray-50 text-grayColor-darkest mb-1">
                    <span class="min-w-32 bg-white overflow-visible">非常棒 / {{ store.comments.count[5] }}則</span>
                    <span class="bg-rating-darkest h-8" :style="{ width: levelCount[4]}"></span>
                  </div>
                  <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
                    <span class="min-w-32 bg-white">蠻好 / {{ store.comments.count[4] }}則</span>
                    <span class="bg-rating-dark h-8" :style="{ width: levelCount[3] }"></span>
                  </div>
                  <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
                    <span class="min-w-32 bg-white">一般 / {{ store.comments.count[3] }}則</span>
                    <span class="bg-rating  h-8" :style="{ width: levelCount[2] }"></span>
                  </div>
                  <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
                    <span class=" min-w-32 bg-white">微差 / {{ store.comments.count[2] }}則</span>
                    <span class="bg-rating-light h-8 " :style="{ width: levelCount[1] }"></span>
                  </div>
                  <div class="flex bg-gray-50 text-grayColor-darkest mb-1">
                    <span class=" min-w-32 bg-white">糟透了 / {{ store.comments.count[1] }}則</span>
                    <span class="bg-rating-lightest h-8" :style="{ width: levelCount[0] }"></span>
                  </div>
                </div>

                <div class="pt-10" v-if="!loading">
                  <div class="py-5 pb-10 border-t border-b border-gray-200" v-for="(comment, index) in store.comments.datas" :key="index">
                    <div class="flex items-center">
                      <h5 class="pr-3 font-bold text-2xl text-grayColor-darkest">
                        {{ comment.score }}
                      </h5>
                      <span v-for="(item, index) in single_star(comment.score)" class="star-item mr-2" :key="index" :class="item"></span>

                    </div>
                    <h5 class="py-4 text-2xl font-semibold text-grayColor-darkest">
                      {{ comment.title }}
                    </h5>
                    <div class="flex items-center">
                      <span class=" flex items-center text-grayColor text-xs font-semibold mr-2">
                        <img class="px-2 transform" src="@/assets/image/svg_user.svg" alt="commentBy" />
                        {{ comment.name }}</span>
                      <span class="text-xs text-gray-400">{{ displayCommentDate(comment.created_at) }}</span>
                    </div>

                    <p class="text-lg font-light tracking-wider pt-4 text-grayColor-darkest word-break">
                      {{ comment.body }}
                    </p>
                    <div class="flex flex-col pt-10">
                      <div class="comment-img-wrap" v-viewer>
                        <img :src="src" class="rounded cursor-pointer mb-2 image commentImageWeb" alt="image from customer" v-for="src in comment.images" :key="src">

                      </div>
                      <!-- <div class="flex relative" :class="{'overflow-hidden' : index !==i, 'flex-wrap':  index ===i ,'commentFifth': comment.images.length > 5  &&  index !==i}" v-viewer>
                        <img :src="src" class="rounded cursor-pointer mb-2 image commentImageWeb" alt="image from customer" v-for="src in comment.images" :key="src">
                        <span class="coverNumWeb text-center" v-if=" index !==i && comment.images.length > 5" @click="clickShowMore(index)">+{{comment.images.length -5 }}</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <!-- <div class="flex justify-center py-10 pagination-wrapper">
                  <ul class="flex">
                    <li v-for="page in filterData.length" :key="page" ref="pages" class="rounded-lg bg-gray-200 px-3 py-2 text-white text-xs  mr-1 font-bold cursor-pointer" :class="current === page - 1 ? 'bg-gray-400' : ''" @click="jumpToPage(page)">
                      {{ page }}
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>

          <div class="sticky padproHidden" v-if="!loading">

            <Card :storeCode="id" :storeID="store.store.id" :average_star="average_star"></Card>

          </div>

        </div>
      </main>
    </div>

    <CardBottom :storeCode="id" :storeID="store.store.id" :store="store" :loading="loading" v-if="!loading" :id="id"></CardBottom>

    <div class="padproHidden">
      <Footer />
    </div>
  </div>

</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import Card from "@/components/Card.vue";
import Tabs from "@/components/Tabs.vue";
import CardStoreInfo from "@/components/CardStoreInfo.vue";
import CarouselBanner from "@/components/CarouselBanner.vue";
import CardBottom from "@/components/CardBottom.vue";
import Footer from "@/components/Footer.vue";
import Map from "@/components/Map.vue";
import moment from "moment";
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.min.css";

import Flickity from "vue-flickity";

const lengths = 5;
const starOn = "on";
const starHalf = "half";
const starOff = "off";

export default {
  name: "Home",
  components: {
    Card,
    Tabs,
    CarouselBanner,
    CardStoreInfo,
    CardBottom,
    Footer,
    Map,
    Carousel,
    Slide,
    Navigation,
    defineComponent,
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: false,
        freeScroll: false,
      },
      stttings: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        bgLazyLoad: 1,
      },
      i: -1,
      right: false,
      left: false,
      showmore: false,
      isShowmore: [],
      boxshow: false,
      current: 0,
      newData: [],
      id: "", //storeCode
      toggleShareLink: false,
      website: "",
    };
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    getData() {
      this.$store.dispatch("getData");
    },
    togglereview() {
      this.boxshow = !this.boxshow;
    },
    clickShowMore(index) {
      this.i = index;
    },
    toComment(value) {
      document
        .getElementById("review")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    toMap() {
      document
        .getElementById("map")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    jumpToPage(page) {
      const vm = this;
      vm.current = page - 1;
    },
    facebookUrl() {
      // const fbhtml_url = window.location.toString();
      // const fbhtml_url = "https://www.google.com/";
      const fbhtml_url = `${process.env.VUE_APP_JOYMAP_URL}/store/${this.id}`;
      window.open("http://www.facebook.com/sharer/sharer.php?u=" + fbhtml_url);
    },
    // preDisable() {
    //   return this.current === 1;
    // },
    // nextDisable() {
    //   return this.current === this.pageLength;
    // },
    single_star(score) {
      const vm = this;
      let single_star = [];
      // for (let i = 0; i < score; i++) {
      // const item = this.store.return.comments.datas[i];
      let fullstar = Math.floor(score);

      for (let i = 0; i < fullstar; i++) {
        single_star.push(starOn);
      }
      if (single_star.length < lengths) {
        let offstar = lengths - single_star.length;
        for (let i = 0; i < offstar; i++) {
          single_star.push(starOff);
        }
      }
      // }
      return single_star;
    },
    displayCommentDate(date) {
      const value = moment(date).format("YYYY/MM/DD");
      return value;
    },
    handleSuccess() {
      Swal.fire({
        html: '<i class="fas fa-check mr-2"></i>複製成功',
        showConfirmButton: false,
        timer: 2000,
        width: 150,
        padding: "0",
      });
    },
    clickMenu(menu) {
      this.$store.dispatch("clickMenu", menu);
    },
    backToApp() {
      this.$router.push({
        name: "Restaurants",
      });
    },
  },
  computed: {
    activeMenuTab() {
      return this.$store.state.activeMenuTab;
    },
    store() {
      return this.$store.state.store;
    },
    loading() {
      return this.$store.state.loading;
    },
    share_fb() {
      return this.$store.state.share_fb;
    },
    filterData() {
      //評論資料
      const vm = this;
      vm.newData = [];
      if (vm.loading === false) {
        vm.store.comments.datas.forEach((item, i) => {
          if (i % 5 == 0) {
            vm.newData.push([]);
          }
          const pagenum = parseInt(i / 5);
          vm.newData[pagenum].push(item);
        });
      }
      return vm.newData;
    },
    average_star() {
      let result = [];
      if (this.loading === false) {
        let score = Math.floor(this.store.comments.avg_score * 2) / 2;
        let starhalf = score % 1 != 0 ? true : false;
        let fullstar = Math.floor(score);
        for (let i = 0; i < fullstar; i++) {
          result.push(starOn);
        }
        if (starhalf) {
          result.push(starHalf);
        }
        if (result.length < lengths) {
          let offstar = lengths - result.length;
          for (let i = 0; i < offstar; i++) {
            result.push(starOff);
          }
        }
        return result;
      }
    },
    levelCount() {
      let arr = [];
      let percent = [];
      for (let item in this.store.comments.count) {
        arr.push(this.store.comments.count[item]);
      }
      arr.forEach((item, i) => {
        item = Math.round((item / this.store.comments.count.total) * 100) + "%";
        percent.push(item);
      });
      return percent;
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
    cardboxshow() {
      return this.$store.state.boxshow;
    },
    checkIsAppleDevice() {
      if (/(iPhone)/i.test(navigator.userAgent)) {
        return true;
      }
      return false;
    },
    isWebview() {
      if (this.checkIsAppleDevice) {
        let useragent = navigator.userAgent;
        let rules = ["WebView", "(iPhone|iPod|iPad)(?!.*Safari/)"];
        let regex = new RegExp(`(${rules.join("|")})`, "ig");
        return Boolean(useragent.match(regex));
      } else {
        return false;
      }
    },
    isAllWebview() {
      let useragent = navigator.userAgent;
      let rules = [
        "WebView",
        "(iPhone|iPod|iPad)(?!.*Safari/)",
        "Android.*(wv|.0.0.0)",
      ];
      let regex = new RegExp(`(${rules.join("|")})`, "ig");
      return Boolean(useragent.match(regex));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // document.addEventListener("click", (e) => {
    //   if (e.target.className !== "shareBoxWrap") {
    //     this.toggleShareLink = false;
    //   }
    // });
  },

  beforeDestroy() {
    window.removeEventListener("click", () => {}, true);
  },
  created() {
    this.id = this.$route.params.id; //storeCode
    this.$store.commit("STORECODE", this.$route.params.id);
    this.website = `${process.env.VUE_APP_JOYMAP_URL}/store/${this.id}`;
    this.getData();
  },
};
</script>

<style lang="scss">
.app-back-icon {
  position: fixed;
  left: 20px;
  top: 70%;
  z-index: 10;
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}
.cardFixed {
  position: sticky;
  top: 0;
}

.container {
  position: relative;
  margin: auto;
}

.sticky {
  position: sticky;
  top: 0;
}

.storeSummary {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.17rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.83rem;
  }
  h6 {
    font-size: 0.67rem;
  }
}
.storeSummary {
  .ql-indent-1 {
    padding-left: 3em;
  }
  .ql-indent-2 {
    padding-left: 6em;
  }
  .ql-indent-3 {
    padding-left: 9em;
  }
  .ql-indent-4 {
    padding-left: 12em;
  }
  .ql-indent-5 {
    padding-left: 15em;
  }
  .ql-indent-6 {
    padding-left: 18em;
  }
  .ql-indent-7 {
    padding-left: 21em;
  }
  .ql-indent-8 {
    padding-left: 24em;
  }
}

.word-break {
  word-break: break-word;
}
.banner {
  height: 70vh;
  padding: 0px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 768px) {
  .banner {
    height: 20vh;
  }
}

.carousel__next {
  position: absolute;
  right: 20px;
  font-size: 1rem;
  color: white;
  outline: none;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}

.carousel__next :focus {
  outline: none;
}

.carousel__prev {
  position: absolute;
  left: 20px;
  font-size: 1rem;
  color: white;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}

.carousel__viewport,
.carousel__track,
.carousel__slide,
.carousel__slide img {
  height: 100%;
}
.menuSwiper {
  height: 200px;
}

@media (max-width: 375px) {
  .menuSwiper {
    height: 150px;
  }
}
.shareBox {
  position: absolute;
  top: 30px;
  left: -10px;
  width: 400px;
  padding: 12px;
}
.shareBox span {
  display: flex;
  align-items: center;
  width: 70px;
  margin: 0.5rem 0 0.5rem 0;
  padding: 0 0.5rem 0 0.5rem;
  border-radius: 5px;
  background: #3b5998;
  color: white;
}

.copyIcon {
  position: absolute;
  right: 10px;
}
.swal2-html-container {
  margin: 18px;
}

.padproHidden {
  display: none;
}
@media (min-width: 1280px) {
  .padproHidden {
    display: block;
  }
}
@media (min-width: 1024px) {
  .padproHidden {
    display: block;
  }
}

.padproHiddenFlex {
  display: none;
}
@media (min-width: 1024px) {
  .padproHiddenFlex {
    display: flex;
  }
}
.padproHide {
  display: block;
}
@media (min-width: 1024px) {
  .padproHide {
    display: none;
  }
}

.container-px {
  padding: 10px 0px;
}

@media (min-width: 1024px) {
  .container-px {
    padding: 40px 40px;
  }
}
.h1pd {
  padding: 8px 32px;
}

@media (min-width: 1024px) {
  .h1pd {
    padding: 0;
  }
}
.mbgap {
  margin-bottom: 100px;
}

@media only screen and (device-height: 667px) {
  .isIosWebview {
    margin-bottom: 130px;
  }
}

@media only screen and (device-height: 736px) {
  .isIosWebview {
    margin-bottom: 130px;
  }
}

@media only screen and (device-height: 812px) {
  .isIosWebview {
    margin-bottom: 130px;
  }
}

@media only screen and (device-height: 896px) {
  .isIosWebview {
    margin-bottom: 130px;
  }
}

.min-w-32 {
  min-width: 8rem;
}

@media (max-width: 1023px) {
  .mobildFixed {
    position: fixed;
  }
}

/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: 0;
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 150px !important;
  width: 100%;
  object-fit: cover;
  background-position: center center;
}
@media (min-width: 768px) {
  .flickity-viewport {
    height: 229px !important;
  }
}

@media (min-width: 1024px) {
  .flickity-viewport {
    height: 236px !important;
  }
}
.carousel-cell {
  object-fit: cover;
  background-position: center center;
  width: 150px;
  height: 150px;
  border-radius: 6px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .carousel-cell {
    width: 229px;
    height: 229px;
    margin-right: 10px;
  }
}

@media (min-width: 1024px) {
  .carousel-cell {
    width: 236px;
    height: 236px;
    margin-right: 10px;
  }
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(200px);
}
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}
.flickity-button:hover {
  background: #fff;
  cursor: pointer;
}
.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f;
}
.flickity-button:active {
  opacity: 0.6;
}
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  pointer-events: none;
}
.flickity-button-icon {
  fill: currentColor;
}
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.flickity-button {
  background: rgba(255, 255, 255, 0.692);
}

.flickity-prev-next-button {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .flickity-prev-next-button {
    width: 30px;
    height: 30px;
  }
}
/* icon color */
.flickity-button-icon {
  fill: #313545;
}

.flickity.hidebtn button {
  display: none;
}

.event-tag {
  color: #fa6c3e;
  border: solid 1px #fa6c3e;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 12px;
  margin: 0rem 2rem 0.3rem 2rem;
  display: inline-block;
  img {
    display: inline-block;
    // width: 14px;
    // height: 14px;
  }
}

@media (min-width: 768px) {
  .event-tag {
    padding: 6px 10px;
    font-size: 15px;
  }
}

@media (min-width: 1024px) {
  .event-tag {
    margin: 21px 0 0 0;
  }
}
.comment-img-wrap {
  display: flex;
  flex-wrap: wrap;
}
.gap-4{
  gap: 10px;
}
</style>
