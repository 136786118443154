<template>
  <div class="card cardFixed padproHidden">
    <div class="flex flex-col justify-center bg-white rounded-lg shadow-md card-padding " v-if="!loading">
      <div class="padproHidden">
        <CardStoreInfo :average_star="average_star"></CardStoreInfo>
      </div>
      <div class="w-full">
        <button class="mt-5 text-white bg-main btn-full btn" v-if="!boxshow" @click="togglebox">
          訂位選擇<span class=""><img class="" src="@/assets/image/svg_open.svg" alt="close" /></span>
        </button>
        <button class="mt-5 text-main border border-main btn-full btn" v-if="boxshow" @click="togglebox">
          訂位選擇<span class=""><img class="" src="@/assets/image/svg_close.svg" alt="close" /></span>
        </button>
        <transition name="router-slid" :adultNum="adultNum" :childNum="childNum" :childseat="childseat">
          <div v-show="boxshow">
            <div class="mt-5 text-gray-500">
              <div class="flex justify-between items-center">
                <span class="font-bold w-1/4">成人</span>
                <div class="flex flex-row border h-10 rounded-md border-gray-300 relative shadow w-32">
                  <button class="hover:bg-gray-200 border-gray-400 h-full w-28 flex rounded-l focus:outline-none focus cursor-pointer items-center" @click.prevent="adultNumber(-1)" :disabled="adultNum === 1">
                    <span class="m-auto text-2xl font-extralight">-</span>
                  </button>
                  <div class="bg-white w-24 text-xs flex items-center justify-center cursor-default px-6">
                    <input type="hidden" class="border-gray-400 focus:outline-none text-center" name="custom-input-number" value="adultNum" />
                    <span class="text-sm font-bold text-black">{{ adultNum }}</span>
                  </div>
                  <button class="hover:bg-gray-200 border-gray-400 h-full w-28 flex rounded-r focus:outline-none cursor-pointer" :class="{isFull :isFull}" @click.prevent="adultNumber(+1)" :disabled="adultNum === store.order_settings.single_time_order_total || (adultNum + childNum) >=  store.order_settings.single_time_order_total">
                    <span class="m-auto text-2xl font-extralight">+</span>
                  </button>
                </div>
                <span class="font-bold text-right w-1/4">位</span>
              </div>

              <div>
                <div class="flex justify-between items-center mt-5">
                  <span class="font-bold w-1/4">小孩</span>
                  <div class="flex flex-row border h-10 w-32 rounded-md border-gray-300 relative shadow">
                    <button class="hover:bg-gray-200 border-gray-400 h-full w-28 flex rounded-l focus:outline-none cursor-pointer items-center" @click.prevent="childNumber(-1)" :disabled="childNum === 0">
                      <span class="m-auto text-2xl font-extralight">-</span>
                    </button>
                    <div class="bg-white w-24 text-xs flex items-center justify-center cursor-default px-6">
                      <input type="hidden" class="border-gray-400 focus:outline-none text-center" readonly name="custom-input-number" :value="childNum" />
                      <span class="text-sm font-bold text-black">{{ childNum }}</span>
                    </div>
                    <button class="hover:bg-gray-200 border-gray-400 h-full w-28 flex rounded-r focus:outline-none cursor-pointer" :class="{isFull :isFull}" @click.prevent="childNumber(+1)" :disabled="adultNum ===  store.order_settings.single_time_order_total || (adultNum + childNum) >= store.order_settings.single_time_order_total">
                      <span class="m-auto text-2xl font-extralight">+</span>
                    </button>
                  </div>
                  <span class="font-bold w-1/4 text-right">位</span>
                </div>

                <div v-if="childNum >= 1">
                  <div class="flex justify-between items-center mt-5">
                    <span class="font-bold w-1/4">兒童座椅</span>
                    <div class="flex flex-row border h-10 w-32 rounded-md border-gray-300 relative shadow">
                      <button class="hover:bg-gray-200 border-gray-400 h-full w-28 flex rounded-l focus:outline-none cursor-pointer items-center" @click.prevent="childseatQtyMinus(-1)">
                        <span class="m-auto text-2xl font-extralight">-</span>
                      </button>
                      <div class="bg-white w-24 text-xs flex items-center justify-center cursor-default px-6">
                        <input type="hidden" class="border-gray-400 focus:outline-none text-center" :value="childseat" />
                        <span class="text-sm font-bold text-black">{{childseat}}</span>
                      </div>
                      <button class="hover:bg-gray-200 border-gray-400 h-full w-28 flex rounded-r focus:outline-none cursor-pointer" :class="{isFull :isSeatFull}" @click.prevent="childseatQtyPlus(+1)">
                        <span class="m-auto text-2xl font-extralight">+</span>
                      </button>
                    </div>
                    <span class="font-bold w-1/4 text-right">張</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-gray-100 p-5 my-5">
              <p class="text-sm text-center px-2 text-gray-500 font-bold">
                可接受 1-{{store.order_settings.single_time_order_total}} 位訂位（含大人與小孩） <br />
                * 超過 {{store.order_settings.single_time_order_total}}人 或 有訂位以外的需求，<br />請使用電話預約！
              </p>
            </div>
            <!-- <Calendar :canOrderTimes="canOrderTimes" :store="store"></Calendar> -->
            <Calendar :canOrderTimes="canOrderTimes" :store="store" @update="updateDate"></Calendar>
            <div class="wrap">
              <div class="cont">
                <div class="my-4 md:flex md:flex-wrap md:justify-start text-center state orderTimeWrap">
                  <button class="mr-1 timeOption hover:bg-yellow-100" v-for="i in businessHour" :key="i" :class="{unavaliableTime: getArrDifference.find((time) => time === i), 
              timeoptionActive: orderTime === i }" @click="onClicktime(i)"> {{i}} </button>
                </div>
              </div>
            </div>
            <p class="pb-5 text-gray-400 font-normal text-sm">
              ＊灰色表已滿，可點選檢視同時段其他可訂位日期
            </p>
            <div class="flex justify-around border-gray-100 border-t-2 pt-5">
              <button class="btn btn-gray" @click="clearAll">清空</button>
              <button class="btn btn-main" :class="{disabledBtn : (!isNext || isChangeMonth)}" @click="confirmDate">
                選擇
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from "@/assets/filters/emitter.js";
import CardStoreInfo from "@/components/CardStoreInfo.vue";
import Calendar from "@/components/Calendar.vue";

import { cloneDate } from "@/assets/filters/date.js";

export default {
  name: "Card",
  components: {
    CardStoreInfo,
    Calendar,
  },
  props: {
    storeCode: {
      type: String,
    },
    storeID: {
      type: Number,
    },
    editDetail: {
      type: Boolean,
    },
    average_star: {
      type: Array,
    },
  },
  mixins: [emitter],
  data() {
    return {
      isloading: false,
      boxshow: false,
      showtime: false,
      // businessHour: [],
      avaliableHour: [],
      // canOrderTimes: [], //同時間其他可選的日期
      limitDays: "",
      // isNext: Boolean,
      adultNum: 2,
    };
  },
  methods: {
    getBusinessTime() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-times/${this.apiDate}/${this.count}`;
      const vm = this;
      vm.isloading = true;

      if (this.apiDate !== "NaN-NaN-NaN") {
        vm.$http
          .get(api)
          .then((response) => {
            this.$store.commit(
              "BUSINESSHOUR",
              response.data.return.business_times
            );
            vm.avaliableHour = response.data.return.can_order_times;
            vm.isloading = false;
            this.defaultTime();
          })
          .catch((error) => {
            // if (error.response.data) {
            vm.loading = false;
            // }
          });
      }
      this.$store.commit("CLICKYEAR", this.tempValue.getFullYear());
      this.$store.commit("CLICKMONTH", this.tempValue.getMonth() + 1);
      this.$store.commit("CLICKDAY", this.tempValue.getDate());
      this.$store.commit("CLICKWEEK", this.tempValue.getDay());
    },
    clickDategetBusinessTime() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-times/${this.apiDate}/${this.count}`;
      const vm = this;
      vm.isloading = true;

      if (this.apiDate !== "NaN-NaN-NaN") {
        vm.$http
          .get(api)
          .then((response) => {
            // vm.businessHour = response.data.return.business_times;
            this.$store.commit(
              "BUSINESSHOUR",
              response.data.return.business_times
            );
            vm.avaliableHour = response.data.return.can_order_times;
            vm.isloading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              vm.loading = false;
            }
          });
      }
      this.$store.commit("CLICKYEAR", this.tempValue.getFullYear());
      this.$store.commit("CLICKMONTH", this.tempValue.getMonth() + 1);
      this.$store.commit("CLICKDAY", this.tempValue.getDate());
      this.$store.commit("CLICKWEEK", this.tempValue.getDay());
    },
    updateDate(val) {
      this.clickDategetBusinessTime();
    },
    getcanOrderDate() {
      const api = `${process.env.VUE_APP_API}/store/${this.storeID}/can-order-date/${this.orderTime}/${this.count}`;
      const vm = this;
      vm.$http.get(api).then((response) => {
        this.$store.commit("CANORDERTIMES", response.data.return.dates);
      });
    },
    confirmDate() {
      const vm = this;
      const d = this.tempValue;
      let date = d.getDay();
      localStorage.setItem("adultNum", vm.adultNum);
      localStorage.setItem("childNum", vm.childNum);
      localStorage.setItem("childseat", vm.childseat);
      localStorage.setItem("tempValue", vm.tempValue);
      localStorage.setItem("orderTime", vm.orderTime);
      // localStorage.setItem("clickDate", date);
      localStorage.setItem("clickWeek", date);
      if (this.orderTime === "" || undefined || null) {
        alert("該時段已客滿，請選擇其他時段");
      } else {
        if (!this.isNext || this.orderTime === "" || undefined || null) {
          alert("該時段已客滿，請選擇其他時段");
        } else {
          this.$router.push(`/store/${this.storeCode}/info`);
        }
      }
    },
    adultNumber(num) {
      this.adultNum = this.adultNum + num;
      this.$store.dispatch("adultNumber", num);
    },
    childNumber(num) {
      this.$store.dispatch("childNumber", num);
    },
    changeNumber() {
      this.$store.dispatch("changeNumber");
    },
    childseatQty(num) {
      this.$store.dispatch("childseatQty", num);
    },
    childseatQtyPlus() {
      this.$store.dispatch("childseatQtyPlus");
    },
    childseatQtyMinus() {
      this.$store.dispatch("childseatQtyMinus");
    },
    togglebox() {
      this.boxshow = !this.boxshow;
    },
    toggleshowtime() {
      this.showtime = !this.showtime;
    },
    onClicktime(i) {
      this.$store.commit("CLICKORDERTIME", i);
      localStorage.setItem("orderTime", JSON.stringify(this.orderTime));
      this.anotherDate();

      this.getcanOrderDate();

      if (
        this.orderTime.length > 0 &&
        this.avaliableHour.includes(this.orderTime)
      ) {
        this.$store.commit("ISNEXT", true);
      } else {
        this.$store.commit("ISNEXT", false);
      }
    },
    anotherDate(data) {
      let d = new Date(data),
        m1 = d.getMonth(data) + 1,
        d1 = d.getDate(data),
        y1 = d.getFullYear(data);
      if (m1 < 10) {
        m1 = "0" + m1;
      }
      if (d1 < 10) {
        d1 = "0" + d1;
      }
      const OptionDate = y1 + "-" + m1 + "-" + d1;
      const result = this.canOrderTimes.find((time) => time === OptionDate);
      return result;
    },
    getDay(cell) {
      return cell.date.getDate();
    },
    clearAll() {
      this.$store.commit("ADULTNUM", 1);
      this.$store.commit("CHILDNUM", 0);
      this.$store.commit("CHILDSEATNUM", 0);
      this.$store.commit("ORDERTIME", "");
      this.$store.commit("TEMPVALUE", this.value);
    },
    defaultTime() {
      const defaultArray = [];
      const today = new Date().toLocaleDateString();
      const found = this.avaliableHour.find((element) => {
        const ele = new Date(today + " " + element);
        const now = new Date();
        return ele > now;
      });
      defaultArray.push(found);
      this.$store.commit("CLICKORDERTIME", defaultArray[0]);

      if (this.orderTime.length === 0) {
        this.$store.commit("ISNEXT", false);
      } else {
        this.$store.commit("ISNEXT", true);
      }
    },
  },
  computed: {
    businessHour() {
      return this.$store.state.businessHour;
    },
    canOrderTimes() {
      return this.$store.state.canOrderTimes;
    },
    isChangeMonth() {
      return this.$store.state.isChangeMonth;
    },
    isFull() {
      if (this.count === this.store.order_settings.single_time_order_total) {
        return true;
      } else {
        return false;
      }
    },
    isSeatFull() {
      const maxSeat = this.childNum;
      if (this.childseat === maxSeat) {
        return true;
      } else {
        return false;
      }
    },
    isNext() {
      return this.$store.state.isNext;
    },
    // adultNum() {
      // return +localStorage.getItem("adultNum") || 2;
      // return this.$store.state.searchData.total_people;
    // },
    childNum() {
      return this.$store.state.childNum;
    },
    childseat() {
      return this.$store.state.childseat;
    },
    clickYear() {
      return this.$store.state.clickYear;
    },
    clickMonth() {
      return this.$store.state.clickMonth;
    },
    clickDay() {
      return this.$store.state.clickDay;
    },
    clickWeek() {
      return this.$store.state.clickWeek;
    },
    orderTime() {
      return this.$store.state.orderTime;
    },
    value() {
      return this.$store.state.value;
    },
    store() {
      return this.$store.state.store;
    },
    loading() {
      return this.$store.state.loading;
    },
    tempValue() {
      return this.$store.state.tempValue;
    },
    count() {
      return this.adultNum + this.childNum;
    },
    todayBusinessHour() {
      if (
        Object.keys(this.store.return.business_hours).find(
          (element) => element == this.getday
        )
      ) {
        return this.store.return.business_hours[this.getday];
      }
    },
    getArrDifference() {
      return this.businessHour
        .concat(this.avaliableHour)
        .filter(function (v, i, arr) {
          const unavaliable = arr.indexOf(v) === arr.lastIndexOf(v);
          return unavaliable;
        });
    },
    getday() {
      let today = new Date();
      return today.getDay();
    },
    apiDate() {
      let d = new Date(this.tempValue),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return [year, month, day].join("-");
    },
  },

  mounted() {
    this.adultNum = this.$store.state.searchData.total_people;
    if (this.$store.state.tempValue !== "") {
      this.getBusinessTime();
    }
    document.body.addEventListener("click", this.onClickBody);
  },
  beforeUnmount() {
    document.body.removeEventListener("click", this.onClickBody);
  },
  created() {
    this.$store.commit("TEMPVALUE", this.value);
    if (this.$store.state.tempValue !== "") {
      this.getBusinessTime();
    }

    if (this.orderTime.length === 0) {
      this.$store.commit("ISNEXT", false);
    } else {
      this.$store.commit("ISNEXT", true);
    }
  },
};
</script>

<style scoped>
.cardFixed {
  position: sticky;
  top: 0;
}

.orderTimeWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-gap: 20px; */
  /* height: 100px; */
}
.padproHidden {
  display: none;
}

@media (min-width: 1024px) {
  .padproHidden {
    display: block !important;
  }
}
.disabledBtn {
  pointer-events: none;
  opacity: 0.5;
}
.isFull {
  cursor: default;
  opacity: 0.7;
  background: rgba(236, 236, 236, 0.582);
  pointer-events: none;
}

.card-padding {
  padding: 28px 56px;
}
@media (min-width: 1024px) {
  .card-padding {
    padding: 28px 36px;
  }
}

@media (min-width: 1200px) {
  .card-padding {
    padding: 28px 68px;
  }
}
</style>