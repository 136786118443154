<template>
  <div class="flex justify-center md:pt-5 lg:pt-10 container mx-auto" id="map">
    <div class="w-full">
      <GMapMap :center="center" :zoom="16" map-type-id="roadmap" >
        <!-- <GMapCluster> -->
        <GMapMarker :position="center" :clickable="true" :draggable="false" @click="center=center" :icon="{ url: require('@/assets/image/Localiser.svg'),  scaledSize: { width: 80, height: 80, }}" />
        <!-- </GMapCluster> -->
      </GMapMap>
    </div>
  </div>
</template>

<script>
export default {
  name: "Map",
  components: {},
  props: {
    location: "",
  },

  data() {
    return {
      center: {
        lat: Number,
        lng: Number,
      },
    };
  },
  methods: {
    getLatLng() {
      const value = this.location
        .replaceAll(/ /g, ",")
        .replace("POINT(", "")
        .replace(")", "")
        .split(",");
      this.center.lat = parseFloat(value[0]);
      this.center.lng = parseFloat(value[1]);
    },
  },
  computed: {
    // coordinate() {
    //   const value = this.location
    //     .replaceAll(/ /g, ",")
    //     .replace("POINT(", "")
    //     .replace(")", "")
    //     .split(",");
    //   return value;
    // },
  },
  created() {
    this.getLatLng();
  },
};

</script>

<style scoped>
.vue-map-container {
  height: 400px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .vue-map-container {
    height: 200px;
  }
  #map {
    height: 200px;
  }
}

</style>
